.global-alert {
    position: fixed; /* Position the alert relative to the viewport */
    top: -100px; /* Initially position it above the viewport */
    left: 50%; /* Center it horizontally */
    transform: translateX(-50%); /* Center it horizontally */

    /* Additional styling for your alert, e.g., width, padding, etc. */
    width: 500px;
    padding: 10px;
    z-index: 1000; /* Adjust the z-index to control its stacking order */
    transition: top 0.3s ease-in-out; /* Add a smooth transition for the top property */
}

.global-alert.show {
    top: 0; /* Bring the alert into view when it's shown */
}


.alert-content {
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    text-align: center; /* Center text within */
    padding: 10px; /* Add padding to center the text within the alert */
}