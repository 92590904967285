.appoinment-wrapper {
    padding-bottom: 120px;
}

/* Style Field components generated by Formik */
.appoinment-form form .row > div {
    padding-right: 15px;
    padding-left: 15px;
}

.appoinment-form form input[type="text"],
.appoinment-form form input[type="email"],
.appoinment-form form input[type="phone"],
.appoinment-form form input[type="text"],
.appoinment-form form textarea {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 5.288px 11.876px 40px 0 rgb(0 0 0 / 5%);
    height: 68px;
    border: none;
    line-height: 68px;
    width: 100%;
    color: #666;
    font-size: 16px;
    padding-left: 30px;
    font-weight: 300;
    margin-top: 30px;
}

textarea {
    min-height: 300px;
    overflow: auto;
    resize: vertical;
}

.form-btn {
    width: 200px;
}

.form-btn:hover {
    border: 1px solid #fc5185 !important;
}

/* Style error messages container */
.error-container {
    max-height: 80px; /* Set a fixed maximum height */
    overflow: hidden; /* Hide overflow */
}
